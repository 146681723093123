<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2024-11-12 17:03:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\seleteDept.vue
-->
<template>
  <div class="appoint">
    <div class="hosTitle" @click="routerTo"> 
      <img :src="hospData.ghymtp" alt="" style="width: 120px;height: 80px;padding: 0 10px;">
      <div class="hosInfo">
        <div style="display: flex;justify-content: space-between;align-items: center;" class="name">
          {{ hospData.hospName }}
          <van-icon
            class="number"
            name="arrow"
            color="#4f4f4f"
            size="20"
          />
        </div>
        <div class="adress">
          {{ hospData.address }}
        </div>
      </div>
    </div>
    <van-tree-select height="93vh" :items="deptList" :main-active-index.sync="select_active" class="office" @click-item="onNavClick" />
    <van-dialog v-model="show" :title="hospInfo.descTitle" show-confirm-button theme="round-button" confirm-button-text="我知道了" confirm-button-color="#3A8AE5">
      <div class="body" v-html="hospInfo.content" />
    </van-dialog>
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'
import {setItem, getItem } from '../../../utils/cache'

export default {
    data(){
        return{
            deptList:[], //科室列表
            select_active: 0,
            show:false,  //预约须知
            hospData:{},
            hospInfo:{},
            flag:'',
            yyxzStatus:0,
            routerPath:''
        }
    },
    mounted() {
        this.getQueryRegisterDept()
        this.getQueryRegisterData()
    },
    created(){
      this.yyxzStatus = getItem("yyxzStatus")
      this.gjcDepartDesc()
    },
    methods:{
        //获取科室列表
        getQueryRegisterDept(){
            gjcModel.getQueryRegisterDept({"hospCode":''}).then((result) => {
                result.data.forEach((item)=> {
                    const selectDeps = {}
                    selectDeps.text = item.deptName
                    selectDeps.id = parseInt(item.deptCode)
                    const childArray = []
                    item.childList.forEach((children)=> {
                        const smallDeps = {}
                        smallDeps.text = children.deptName
                        smallDeps.id = parseInt(children.deptCode)
                        childArray.push(smallDeps)
                    })
                    selectDeps.children = childArray
                    this.deptList.push(selectDeps)
                })
            })
           
        },
        gjcDepartDesc(){
          gjcModel.gjcDepartDesc('YHXZ').then((result) => {
                console.log('医院介绍', result)
                this.hospInfo = result.data
                if (this.yyxzStatus === 1) {
                  this.show = true
                  setItem("yyxzStatus",0)
                }
            })
        },
        getQueryRegisterData(){
            const params = {
                yydm: '' //医院代码（医院唯一标识）
            }
            gjcModel.getHospitalIntroductionData(params).then((result) => {
                console.log('医院介绍', result,this.yyxzStatus)
                this.hospData = result.data
            })
        },
        routerTo(){
          this.$router.push({
            path: '/gjcHosp'
          })
        },
        onNavClick(item){
            console.log('选择科室', item)
            this.$router.push({
                path: '/deptSource',
                query: {
                    deptCode:String(item.id)
                    // deptName:item.text
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
.van-sidebar-item--select{
color: #1e8dff !important;
font-weight: bold;
font-family: PingFangSC, PingFang SC;
}
.van-sidebar-item--select::before{
  background-color: #1e8dff !important;
}
.hosTitle{
  display: flex;
  padding: 20px 20px 20px 0;
}
.van-dialog{
  top: 50% !important;
}
.hosInfo{
  font-size: 28px;
  .adress{
    padding: 60px 10px 10px 0px;
    font-size: 24px;
    color: #555b5e;
  }
  .name{
    padding: 10px 0;
    font-weight: bolder;
  }
}
.body{
  padding: 10px 20px;
  font-size: 26px;
  color: #222b31;
  line-height: 1.5;
}
</style>
